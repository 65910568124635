/* lato-regular - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lato-v23-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lato-v23-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-v23-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lato-v23-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-v23-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lato-v23-latin-ext_latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-italic - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/lato-v23-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lato-v23-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-v23-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lato-v23-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-v23-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lato-v23-latin-ext_latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
  }
  /* lato-700 - latin-ext_latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lato-v23-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/lato-v23-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-v23-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lato-v23-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-v23-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lato-v23-latin-ext_latin-700.svg#Lato') format('svg'); /* Legacy iOS */
  }